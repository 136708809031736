import React from "react";
import "../../Assets/css/case.css";
import Parallax from "react-rellax";
import $ from "jquery";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { Link } from "gatsby";
import Header from "../../components/header";
import Footer from "../../components/footer";
import ReactPlayer from "react-player";
import "../../Assets/css/case.css";
import "../../Assets/css/base.css";
import "../../Assets/css/demo1.css";
import "../../Assets/css/home.css";
import SEO from "../../components/seo";

if (typeof window !== "undefined") {
  require("jquery-simplyscroll");
}

class Hirafi extends React.Component {
  componentDidMount() {
    require("paroller.js");
    $(document).ready(function () {
      $(".paroller-example").paroller({
        factor: 0.15,
        type: "foreground",
        direction: "vertical",
      });
    });

    $("#scroller").simplyScroll({
      auto: true,
      autoMode: "loop",
      customClass: "simply-scroll",
      direction: "backward",
      frameRate: 60,
      initialOffset: 0,
      manualMode: "end",
      orientation: "horizontal",
      pauseButton: false,
      pauseOnHover: false,
      pauseOnTouch: false,
      speed: 8,
      startOnLoad: false,
    });

    $("#scroller2").simplyScroll({
      auto: true,
      autoMode: "loop",
      customClass: "simply-scroll",
      direction: "forwards",
      frameRate: 60,
      initialOffset: 0,
      manualMode: "end",
      orientation: "horizontal",
      pauseButton: false,
      pauseOnHover: false,
      pauseOnTouch: false,
      speed: 3,
      startOnLoad: false,
    });

    $("#scroller3").simplyScroll({
      auto: true,
      autoMode: "loop",
      customClass: "simply-scroll",
      direction: "backward",
      frameRate: 60,
      initialOffset: 0,
      manualMode: "end",
      orientation: "horizontal",
      pauseButton: false,
      pauseOnHover: false,
      pauseOnTouch: false,
      speed: 1,
      startOnLoad: false,
    });
  }

  componentWillMount() {}

  render() {
    return (
      <div>
        <SEO
          title="Hirafi · A dedicated online series for Algerian Artisans."
          description=" The idea behind hirafi dates from 2017, we wanted to
                    highlight the often forgotten and neglected craftsmen of
                    this Algerian era."
          image={
            "https://makook.space" +
            require("../../images/Hirafi/Hirafi-poster.jpg")
          }
        ></SEO>
        <Header />

        <div
          data-post-title="makookcase"
          data-theme="theme-dark"
          className="theme-dark"
        >
          <main className="case makookcase">
            <h1 className="master-title">Hirafi</h1>

            <div
              className="background-color-makookcase-1 color-transition"
              data-color="#000"
            >
              <section className="image-block sticky-title-padding-top mobile-hero no-margin-bottom sticky-stop">
                <figure>
                  <ReactPlayer
                    className=""
                    url="https://www.youtube.com/watch?v=Wm54gRrcWrA"
                    playing
                    muted
                    loop
                    width="100%"
                    height="720px"
                  />
                  {/* <Img fluid={this.props.data.imageOne.childImageSharp.fluid} /> */}
                  <Parallax speed={-2} percentage={0.5} centered>
                    <p className=" desktop-only">
                      A dedicated online series for Algerian Artisans.
                    </p>
                  </Parallax>

                  <Parallax speed={-2} percentage={0.5} centered>
                    <p className="mobile-only">
                      A dedicated online series for Algerian Artisans.
                    </p>
                  </Parallax>
                </figure>
              </section>

              <section className="image-text push-up half-padding-bottom no-margin-bottom">
                <figure>
                  <Img fluid={this.props.data.imageTwo.childImageSharp.fluid} />
                </figure>
                <article>
                  <span>Date</span>
                  <p>2017 – 2019</p>
                  <span>Challenge </span>
                  <p>
                    Since 1962 the Algerian culture witnessed a lot of changes,
                    but always stayed true to its origins preserving its
                    authenticity. A lot of brave men and women helped safe guard
                    the Algerian heritage through their craft, transmitting the
                    acquired knowledge from generation to another. These
                    guardians are the Artisans. Yes,the book makers, wood
                    workers, steel benders, glass blowers ...etc. Now, In this
                    modern, digital focused world the country's noticing a
                    significant decline regarding artisans. Which has a direct
                    impact on the Algerian identity as it is. The knowledge of
                    generations could be lost.
                  </p>
                  <span>Solution</span>
                  <p>
                    The idea behind hirafi dates from 2017, we wanted to
                    highlight the often forgotten and neglected craftsmen of
                    this Algerian era. It is trough these guardians of heritage
                    that we wanted to express ourselves and our art and say —
                    here in Algeria passionate people work daily despite the
                    various difficulties they encounter.
                    <br />
                    With a desire to show only true to life imagery and the most
                    sincere moments. We chose to avoid staging, every single
                    frame was taken freehand, in one take, showcasing the
                    incredible art of these crafts men and women. futhermore
                    avoiding fiction at all cost.
                    <br />
                    Hirafi meets Algerian craftsmen through the 48 wilayas. A
                    quest in search of these talented and courageous men and
                    women who help safeguard the Algerian identity through their
                    works. The main goal behind the series is to make These
                    brave heros known to the general public with a positive and
                    hopeful message.
                  </p>
                  <span>Disciplines</span>
                  <p>
                    Cinematography
                    <br />
                    Audio
                    <br />
                    Art Direction
                    <br />
                    Production
                    <br />
                  </p>
                </article>
              </section>

              <section className="mobile-text mobile-only">
                <article>
                  <div>
                    <span>Date</span>
                    <p>2017 – 2019</p>
                  </div>
                  <div>
                    <span>Challenge</span>
                    <p>
                      Since 1962 the Algerian culture witnessed a lot of
                      changes, but always stayed true to its origins preserving
                      its authenticity. A lot of brave men and women helped safe
                      guard the Algerian heritage through their craft,
                      transmitting the acquired knowledge from generation to
                      another. These guardians are the Artisans. Yes, the book
                      makers, wood workers, steel benders, glass blowers ...etc.
                      Now, In this modern, digital focused world the country's
                      noticing a significant decline regarding artisans. Which
                      has a direct impact on the Algerian identity as it is. The
                      knowledge of generations could be lost.
                    </p>
                  </div>
                  <div>
                    <span>Solution</span>
                    <p>
                      The idea behind hirafi dates from 2017, we wanted to
                      highlight the often forgotten and neglected craftsmen of
                      this Algerian era. It is trough these guardians of
                      heritage that we wanted to express ourselves and our art
                      and say — here in Algeria passionate people work daily
                      despite the various difficulties they encounter.
                      <br />
                      With a desire to show only true to life imagery and the
                      most sincere moments. We chose to avoid staging, every
                      single frame was taken freehand, in one take, showcasing
                      the incredible art of these crafts men and women.
                      futhermore avoiding fiction at all cost.
                      <br />
                      Hirafi meets Algerian craftsmen through the 48 wilayas. A
                      quest in search of these talented and courageous men and
                      women who help safeguard the Algerian identity through
                      their works. The main goal behind the series is to make
                      These brave heros known to the general public with a
                      positive and hopeful message.
                    </p>
                  </div>
                  <div>
                    <span>Disciplines</span>
                    <p>
                      Cinematography
                      <br />
                      Audio
                      <br />
                      Art Direction
                      <br />
                      Production
                      <br />
                    </p>
                  </div>
                </article>
              </section>
            </div>

            <div
              className="background-color-makookcase-2 color-transition"
              data-color="#1b1b1b"
            >
              <section className="no-padding">
                <div className="simply-scroll simply-scroll-container">
                  <div className="simply-scroll-clip">
                    <ul id="scroller" className="simply-scroll-list">
                      <li>
                        <figure>
                          <img
                            src={require("../../images/Hirafi/khaled-mahiout.jpg")}
                            alt="episode"
                          />

                          <div className="marquee-item-label typography-marquee-label">
                            <span className="marquee-item-title">
                              Khaled Mahiout
                            </span>
                            <span className="typography-episode-label">
                              Cabinetmaker{" "}
                            </span>
                          </div>
                        </figure>
                      </li>

                      <li>
                        <figure>
                          <img
                            src={require("../../images/Hirafi/Aissou-boussad.jpg")}
                            alt="episode"
                          />
                          <div className="marquee-item-label typography-marquee-label">
                            <span className="marquee-item-title">
                              Aissou Boussad
                            </span>
                            <span className="typography-episode-label">
                              Glassblower
                            </span>
                          </div>
                        </figure>
                      </li>

                      <li>
                        <figure>
                          <img
                            src={require("../../images/Hirafi/khaled-chafaa.jpg")}
                            alt="episode"
                          />
                          <div className="marquee-item-label typography-marquee-label">
                            <span className="marquee-item-title">
                              Khaled Chafaa
                            </span>
                            <span className="typography-episode-label">
                              Luthier{" "}
                            </span>
                          </div>
                        </figure>
                      </li>

                      <li>
                        <figure>
                          <img
                            src={require("../../images/Hirafi/kamel-boubakeur.jpg")}
                            alt="episode"
                          />
                          <div className="marquee-item-label typography-marquee-label">
                            <span className="marquee-item-title">
                              Kamel Boubakeur
                            </span>
                            <span className="typography-episode-label">
                              ceramist{" "}
                            </span>
                          </div>
                        </figure>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="simply-scroll simply-scroll-container no-padding">
                  <div className="simply-scroll-clip">
                    <ul id="scroller2" className="simply-scroll-list">
                      <li>
                        <figure>
                          <img
                            src={require("../../images/Hirafi/Hmed-zidi.jpg")}
                            alt="episode"
                          />
                          <div className="marquee-item-label typography-marquee-label">
                            <span className="marquee-item-title">
                              Hmed Zidi
                            </span>
                            <span className="typography-episode-label">
                              ironworker{" "}
                            </span>
                          </div>
                        </figure>
                      </li>

                      <li>
                        <figure>
                          <img
                            src={require("../../images/Hirafi/Tarakli-faiza.jpg")}
                            alt="episode"
                          />
                          <div className="marquee-item-label typography-marquee-label">
                            <span className="marquee-item-title">
                              Faiza Tarakli
                            </span>
                            <span className="typography-episode-label">
                              Artisan Chocolatier
                            </span>
                          </div>
                        </figure>
                      </li>

                      <li>
                        <figure>
                          <img
                            src={require("../../images/Hirafi/Arezki-Fahima.jpg")}
                            alt="episode"
                          />
                          <div className="marquee-item-label typography-marquee-label">
                            <span className="marquee-item-title">
                              Arezki and Fahima
                            </span>
                            <span className="typography-episode-label">
                              cheesemonger{" "}
                            </span>
                          </div>
                        </figure>
                      </li>

                      <li>
                        <figure>
                          <img
                            src={require("../../images/Hirafi/Adel-kerouani.jpg")}
                            alt="episode"
                          />
                          <div className="marquee-item-label typography-marquee-label">
                            <span className="marquee-item-title">
                              Adel Kerouani
                            </span>
                            <span className="typography-episode-label">
                              Sculptor{" "}
                            </span>
                          </div>
                        </figure>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="simply-scroll simply-scroll-container">
                  <div className="simply-scroll-clip">
                    <ul id="scroller3" className="simply-scroll-list">
                      <li>
                        <figure>
                          <img
                            src={require("../../images/Hirafi/zolo-driss.jpg")}
                            alt="episode"
                          />
                          <div className="marquee-item-label typography-marquee-label">
                            <span className="marquee-item-title">
                              Zolo Driss
                            </span>
                            <span className="typography-episode-label">
                              Copper Worker{" "}
                            </span>
                          </div>
                        </figure>
                      </li>

                      <li>
                        <figure>
                          <img
                            src={require("../../images/Hirafi/benbrika-brahim.jpg")}
                            alt="episode"
                          />
                          <div className="marquee-item-label typography-marquee-label">
                            <span className="marquee-item-title">
                              Benbkira Brahim
                            </span>
                            <span className="typography-episode-label">
                              Artisan Bottier
                            </span>
                          </div>
                        </figure>
                      </li>

                      <li>
                        <figure>
                          <img
                            src={require("../../images/Hirafi/bensadouk-mourad.jpg")}
                            alt="episode"
                          />
                          <div className="marquee-item-label typography-marquee-label">
                            <span className="marquee-item-title">
                              Bensadouk Mourad
                            </span>
                            <span className="typography-episode-label">
                              Bookbinder{" "}
                            </span>
                          </div>
                        </figure>
                      </li>

                      <li>
                        <figure>
                          <img
                            src={require("../../images/Hirafi/Adel-kerouani.jpg")}
                            alt="episode"
                          />
                          <div className="marquee-item-label typography-marquee-label">
                            <span className="marquee-item-title">
                              Adel Kerouani
                            </span>
                            <span className="typography-episode-label">
                              Sculptor{" "}
                            </span>
                          </div>
                        </figure>
                      </li>
                    </ul>
                  </div>
                </div>
              </section>

              <section className="makookcase-block-1">
                <h3 className="paroller-example paroller-title">
                  The Website <a href="https://hirafi.org">www.hirafi.org</a>{" "}
                  represents an online business card and portfolio for the
                  Algerian artisans.
                </h3>
              </section>

              <section className="image-block">
                <figure className="">
                  <Img
                    fluid={this.props.data.imageFour.childImageSharp.fluid}
                  />
                </figure>
              </section>

              <section className="image-text half-margin-bottom">
                <figure>
                  <Img
                    fluid={this.props.data.imageFive.childImageSharp.fluid}
                  />

                  <Parallax speed={2}>
                    <h3 className=" mobile-only">
                      Hirafi took us 70+ hours of footage, more than 4 months to
                      film and a lot of travel to make around Algeria, with a
                      one goal in mind : true to life Cinematography
                    </h3>
                  </Parallax>
                </figure>
                <article>
                  <Parallax speed={-2} percentage={0.5} centered>
                    <h3 className=" desktop-only" data-func="caseIntroParallax">
                      Hirafi took us 70+ hours of footage, more than 4 months to
                      film and a lot of travel to make around Algeria, with a
                      one goal in mind : true to life Cinematography
                    </h3>
                  </Parallax>
                </article>
              </section>

              <section className="image-block">
                <figure>
                  <Img fluid={this.props.data.imageSix.childImageSharp.fluid} />
                </figure>
              </section>

              <Footer />
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default Hirafi;

export const HirafiImg = graphql`
  fragment HirafiImg on File {
    childImageSharp {
      fluid(maxWidth: 1400, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const HirafiQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "Hirafi/hirafi-cover.jpg" }) {
      ...fluidImage
    }

    imageTwo: file(relativePath: { eq: "Hirafi/Hirafi-poster.jpg" }) {
      ...fluidImage
    }

    imageThree: file(
      relativePath: { eq: "ServiceTech/Service-Technique-AlgerieNet.jpg" }
    ) {
      ...fluidImage
    }

    imageFour: file(
      relativePath: { eq: "Hirafi/hirafi-website-hirafi.org.jpg" }
    ) {
      ...fluidImage
    }

    imageFive: file(relativePath: { eq: "Hirafi/hand-craft-hirafi.jpg" }) {
      ...fluidImage
    }

    imageSix: file(relativePath: { eq: "Hirafi/on-set.jpg" }) {
      ...fluidImage
    }
  }
`;
